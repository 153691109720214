.structur-container {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    min-height: 2.5rem;
}

.structur-container h2 {
    flex: 1;
    margin: 0;
}

.structur-container button {
    outline: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.docs-container {
    display: flex;
    flex-direction: column;
}

.docs-container .doc {
    display: flex;
    background-color: $gray-500;
    box-sizing: border-box;
    border: 0px solid $gray-700;
    border-bottom: 0px;
    align-items: center;
    min-height: 2.5rem;
}

.docs-container .doc:nth-child(2n+0) {
    background-color: $gray-200;
    box-sizing: border-box;
}

.docs-container .doc:last-child {
    border-bottom: 0px solid $gray-700;
}

.docs-container .doc .link {
    cursor: pointer;
    flex: 1;
}
.docs-container .doc .link:hover {
    color: $secondary;
}

.docs-container .doc button {
    outline: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}