img.descriptionselfassessment {
    color: $success;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height:1.5rem;
    width:1.5rem;
    margin-right:2rem;
}

img.descriptionexternalassessment {
    color: $secondary;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height:1.5rem;
    width:1.5rem;
    margin-right:2rem;
}

span.selfassessment {
    display: block;
    position: relative;
    color: $success;
    font-size: $font-size-base * 2;
    text-align: center;
    background-image: url('../assets/images/Schueler_neu.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height:3rem;
    width:100%;
}

span.externalassessment {
    display: block;
    position: relative;
    color: $secondary;
    font-size: $font-size-base * 2;
    text-align: center;
    background-image: url('../assets/images/Beobachter.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height:3rem;
    width:100%;
}