.react-datepicker-wrapper {
    width: 100%;
    display: inline-block !important;
}

.react-datepicker__input-container {
    width: 100% !important;
    display: inline-block !important;
    border-color: $gray-700 !important;
}

.react-datepicker-wrapper :disabled {
    background-color: $gray-200;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    line-height: $input-line-height !important;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
}

//FOR IE
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .react-datepicker__input-container input {
        padding: $input-padding-y $input-padding-x-sm !important;
    }
}

.react-datepicker__input-container input:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;
    box-shadow: $custom-select-focus-box-shadow;
}

.react-datepicker__close-icon::after {
    background-color: $primary;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin: 0px auto 0px !important;
    display: inline-block !important;
    font-weight: 700;
}