.radiostudents-container {
    display: block;
    position: relative;
    margin: 0.25rem 0.0rem;
}

.radiostudents-label {
    width: 100%;
    cursor: pointer;
    background-color: $primary;
    color: $white;
    margin: 0.0rem 0.0rem;
    padding: 0.3rem 0.3rem;
    outline: none !important;
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;
}

.radiostudents-label:hover {
    background-color: shade-color($primary, 10%);
}

.radiostudents-label:focus {
    box-shadow: $btn-focus-box-shadow;
}

.radiostudents-label span {
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.radiostudents-input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
}

.radiostudents-input:disabled+.radiostudents-label{
    pointer-events: none;
    opacity: 0.65;
}

.radiostudents-input:checked+.radiostudents-label {
    background-color: $secondary;
    box-shadow: $btn-focus-box-shadow;
}