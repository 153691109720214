.multiple-select-container {
    background-color: white;
    overflow-y: scroll;
    max-height: 12.5rem;
    min-height: 12.5rem;
    padding: 0;
    color: $gray-700;
    font-size: $font-size-sm;
    //position: relative;
    display: block;     
}

.multiple-select-container .multiple-content{
    width:max-content;
}

.multiple-select {
    display: block;
    position: relative;
    padding: 0.1rem 0rem 0.1rem 2rem;
    margin: 0rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 1.4rem !important;
    height: 1.4rem !important;
}

/* Hide the browser's default checkbox */

.multiple-select input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    min-height: 1.4rem !important;
    height: 1.4rem !important;
}

.multiple-select .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.4rem;
    width: 1.4rem;
    display: block;
    margin: 0.2rem 0rem 0rem 0.2rem;
    background-image: url('../assets/images/Checkbox.svg');
    background-size: 1.2rem 1.2rem;
    background-repeat: no-repeat !important;
    background-position: 0.0rem center;
}

.multiple-select:hover input~.checkmark {}

.multiple-select input:checked~.checkmark {
    background-position: 0.0rem center;
    background-repeat: no-repeat !important;
    background-image: url('../assets/images/Checkbox_Kreuz.svg');
    background-size: 1.2rem 1.2rem;
    display: block;
}

.multiple-select .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.multiple-select input:checked~.checkmark:after {
    display: block;
}

.multiple-select .checkmark:after {}

.multiple-select-container div .display-school {
    display: block;
    width: 1.5rem;
}

// .multiple-select {
    // width: calc(100% - 1.5rem);    
// }

.multiple-select-popover-school {
    min-width: 19rem;
}