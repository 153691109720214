.absolute-menu {
    position: fixed;
    top: 5.0rem;
    left: 0.0rem;
    bottom: 3rem;
    width: 3rem;
    height: 100%;
    z-index: $zindex-fixed;
    background-color: $primary;
    padding-top: 0.25rem;
}

.navbar-toggler {
    border-color: transparent !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.menu-toggler {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 2rem !important;
    height: 2rem !important;
    display: inline-block;
    cursor: pointer;
    background-position: 0px 0px;
    margin: 0;
    padding: 0;
}

.menu {
    background-color:  tint-color($primary, 0%);
    position: relative;
    z-index: $zindex-fixed;
    top: 4.75rem;
    height: auto;
    bottom: 2.5rem;
    display: block;
}

.menu-container {
    display: block;
    overflow-y: auto;
    width: 100%;
}

.navbar>div{
    width:100%;
}

.navbar ul{
    width:100%;
}

@media (min-width: 576px) {
    .menu {
        position: fixed;
    }
}

.menu-navbarbrand {
    border-bottom: 1px solid $gray-300;
    cursor: default;
    color: $primary !important;
}

.navbarbrand-triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4rem 0.8rem 0.4rem 0;
    border-color: transparent $primary transparent transparent;
    margin: 0 0.2rem 0 0 !important;
    padding: 0 !important;
}

.menu-inquiry {
    height: calc(2.5rem + 1px);
}

.menu-triangle {
    border: 0.35rem solid transparent;
    content: "";
    display: inline-block;
    width: 0.35rem;
    border-left-color: white;
    margin: 0.4rem 0.25rem 0 0;
}

.menu-triangle-open {
    border: 0.35rem solid transparent;
    content: "";
    display: inline-block;
    width: 0.35rem;
    border-top-color: white;
    margin: 0.4rem 0.25rem 0 0;
}

.menu-inquiry span {
    display: inline-block;
}

.menu-inquiry>div {
    justify-content: left !important;
}


.menu-open-inquiry {
    display: block; //border: 2px solid transparent;
    background-color: $secondary;
    color: white;
    font-weight: bold;
    border-radius: 15%;
    padding: 0 0.5rem;
    min-width: 1.0rem;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    text-align: center;
    float: right;
    animation-name: example;
    animation-duration: 1s;
    animation-iteration-count: 1;
    position: relative;
    font-size: 0.75rem !important;
}

.nav-link {
    margin: 0 !important;
    padding: 0 !important;
    line-break: normal !important;
    word-wrap: break-word !important;
}

.nav-item a {
    color: white;
    width: 100%;
    display: block;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
}

.nav-item a:hover {
    //color: darken($primary, 40%);
}

.nav-link:hover {
    margin: 0 !important;
    padding: 0 !important; //color: darken($primary, 40%) !important;
}

.active {
    background-color: shade-color($primary, 40%);
}

.nav-item {
    padding-left: 0.375rem;
    padding-top: 0;
    padding-bottom: 0;
}

.nav-item a:focus {
    outline: 0;
}
.nav-link:focus {
    outline: 0;
}

.active a {
    //color: darken($primary, 40%) !important;
}

.footer-nav-link {
    height: 100%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    border-right: 1px solid #dee2e6 !important;
    margin: 0 !important;
}

.footer-nav-link:last-child {
    border-right: 0px solid #dee2e6 !important;
}

.footer-nav-link a {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
    margin: 0px !important;
}

/* .footer-nav-link {
    height: 100%;
}

.footer-nav-link.active {
    background-color: transparent;
}
.footer-nav-link.active a {
    color: $secondary !important;
}
 */

@-webkit-keyframes example {
    0% {
        left: 0;
    }
    25% {
        left: -1.0rem;
    }
    50% {
        left: 0;
    }
    75% {
        left: -1.0rem;
    }
    100% {
        left: 0;
    }
}

@keyframes example {
    0% {
        left: 0;
    }
    25% {
        left: -1.0rem;
    }
    50% {
        left: 0;
    }
    75% {
        left: -1.0rem;
    }
    100% {
        left: 0;
    }
}