.pravicypolicy-container{
    height: 50vh !important;
    overflow-y:scroll;
    display: block;
    margin:0 !important;
    padding-top:0.5rem;
    text-align: justify;
}
.pravicypolicy-box{
    display: block;
    border: 1px solid black;
    text-align: center;
    padding:0.25rem;
    margin:2rem 0rem;
}

.pravicypolicy-container .form-check-input{
    margin-left:0rem;
}