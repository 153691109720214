.start-description-container {
    display: flex;
    flex-direction: column;
}

.verbexercise-next-button {
    width: 10rem;
    margin-top: 5%;
}

.verbexercise-pdfButton {
    justify-content: right;
    display: flex;   
}

.verb-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 30rem;
}

.verb-card-container {
    display: flex; 
    align-content: center;
    min-height: 17rem;
    min-width: 19rem;
    flex-direction: column;
    width: 50%;
    position: relative;
    border: 3px solid $primary;
    padding-top: 1rem;

    .verb-info-container {
        display: flex;
        justify-content: flex-end;

        // .verbtype-container {
        // }
    
        // .verb-index-length-container {
        //     display: flex;
        //     justify-content: flex-end;
        // }

        .verb-info-label {
            font-size: 1.2rem !important;
        }

        @media (max-width: 1200px) {
            .verb-info-label {
                font-size: 1rem !important;
            }
        }
    }

    .verb-name-label {
        font-size: 2.5rem !important;
        text-align: center;
        margin-top: 1rem;
    }
    @media (max-width: 1200px) {
        .verb-name-label {
            font-size: 2rem !important;
        }
    }
    
    .evaluation-btn-box {
        display: flex;
        justify-content: space-between;
        width: 50%;
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        transform: translateX(50%);

        .verb-smiley-container {
            text-align: center;
            height: 5rem;
            width: 5rem;
            cursor: pointer;

            .verb-smiley {
                width: 100%;
                height: 100%;
                max-width: 5rem;
                max-height: 5rem;
            }
        }
    }
        
}

.no-verbs-label {
    width: 100%;
    display: flex;
    justify-content: center;
}

.next-button-container {
    width: 100%;
    display: flex;
    justify-content: left;
}

.verb-answer-container {
    display: flex;
    align-content: center;
    flex-direction: column;
    min-width: 30rem;

    .verb-answer-card-container{
        min-height: 6rem;
        width: 98%;
        display: flex;
        border-top: 3px solid $primary;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .verb-answer-name-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 45%;

            .verb-answer-label {
                font-size: 1.3rem;
                font-weight: bold;
            }

            .verb-answer-question-label {
                font-size: 1.1rem;
                text-overflow: ellipsis;
            }

            @media (max-width: 1400px) {
                .verb-answer-label {
                    font-size: 1.1rem;
                }

                .verb-answer-question-label {
                    font-size: 0.9rem;
                }
            }

        }

        .verb-answer-input-container {
            width: 55%;
            padding: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    } 
    .verb-answer-card-container:first-child {
        border-top: none;
    }   
}

.verb-interestarea-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 60rem;
    max-width: 95rem;
}

.verbexercise-circles-next-button {
    width: 10%;
}

.verb-interestarea-container {
    height: 39rem;
    display: flex;
    justify-content: space-between;

    .circle-box {
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/venndiagram.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size:100%;        
        position: relative;
        top: -1rem;

        .private-drop-box {
            position: absolute;
            height: 48%;
            width: 18%;
            top: 27%;
            left: 22%;
        }
        .both-drop-box {
            position: absolute;
            height: 36%;
            width: 18%;
            top: 35%;
            left: 43%;
        }
        .work-drop-box {
            position: absolute;
            height: 48%;
            width: 18%;
            top: 27%;
            left: 65%;
        }
    }

    .verb-list-container {
        height: 100%;
        width: 20%;
        border-left: 3px solid $primary;
        display: flex;
        justify-content: center;
    }
    
    .drag-list {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
      
    .drag-list-draggingover {
        border: 2px solid $gray-300;
    }

    .drag-item {
        width: 100%;
        height: 3rem;
        border-bottom: 2px solid $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
    }
    .drag-item:last-child {
        border-bottom: none;
    }

    .drag-item-dragged {
        width: 100%;
        height: 3rem;
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-300;
        font-size: 1.1rem;
    }

    @media (max-width: 1700px) {
        .drag-item {
            font-size: 1rem;
        }
    }
    @media (max-width: 1400px) {
        .drag-item {
            font-size: 0.8rem;
        }
    }
    @media (max-width: 1200px) {
        .drag-item {
            font-size: 0.7rem;
        }
    }
}

.circles-closed {
    
    .circle-box {
        width: 80%
    }

    .notsaved.drag-item:hover {
        background-color: $gray-300;
        user-select: none;
        cursor: pointer;
    }

    .highlight {
        font-weight: bold;
        color: rgb( 121, 172, 44);
        // background-color: $yellow;
    }
    

    .drag-item-private {
        width: 100%;
        height: 3rem;
        border-bottom: 2px solid $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        color: $gray-600;
    }
    .drag-item-private:last-child {
        border-bottom: none;
    }

    @media (max-width: 1700px) {
        .drag-item {
            font-size: 1rem;
        }
        .drag-item-private {
            font-size: 1rem;
        }
    }

    @media (max-width: 1400px) {
        .drag-item {
            font-size: 0.9rem;
        }
        .drag-item-private {
            font-size: 0.9rem;
        }
    }

    @media (max-width: 1200px) {
        .drag-item {
            font-size: 0.7rem;
        }
        .drag-item-private {
            font-size: 0.7rem;
        }
    }
}




