.myresult-saveButton{
    justify-content: left;
    display: flex;
    // width: 96%;
}

.myresult-pdfButton{
    justify-content: right;
    display: flex;
    // width: 96%;
}

.myresults-container.result-print{
    width: 200mm;
    height: 280mm;//297mm;
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: auto;
}
.myresults-container {
    min-width: 40rem;
    width: 98%;
    margin:auto;
    position: relative;
    
    

    .result-box-img{
        justify-content: center;
        height: 1em;
        
        .result-box-img-inner{
            margin-top: -70px;
        }
    }

    .result-box-list{
        list-style: square inside;
        // color: $primary;
    }

    .result-box-list li{
        color:$primary;
    }
    
    .result-box-list li span{
        color: black;
    }
   

    .result-box-textarea{
        width: 100%;
        min-height: 5rem;
        // overflow: hidden;
    }

    .result-box-select{
        width: 95%;
        margin-left: 1%;
    }
    
    .result-box-select.bottom{
        width: 88%;
    }
    
    .result-boxes-headline{
        z-index: 2;
        background-color: $primary;
        color: white;
        text-overflow: ellipsis;
        width: 70%;
        height: 3rem;
        line-height: 3rem;
        position:relative;
        font-size: large;
        padding-left: 1rem;
    }

    .result-boxes-headline.red{
        background-color: #d11b28;
    }

    .result-boxes{
        margin-top: -1rem;
        z-index: 2;
        border: 2px solid $primary;
        height: 85%;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    .result-boxes.bottom{
        width: 102%;    
        position:relative;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    // .result-bottomright{
    //     z-index: 2;
    //     background:linear-gradient(-45deg, transparent calc(50px + 2 * 0.414px), $primary 0) bottom right;
    // }

    // .result-bottomright::before{
    //     z-index: 1;
    //     width: calc(100% - 8 * 1px);
    //     height: calc(100% - 8 * 1px);
    //     content: "";
    //     display: block;
    //     background: linear-gradient(-45deg, transparent 50px, $white 0) bottom right;
    //     position: absolute;
    //     left: 4px;
    //     top: 4px;
    // }


    // .result-bottomleft{
    //     z-index: 2;
    //     background: linear-gradient(45deg, transparent calc(50px + 1 * 0.414px), $primary 0) bottom left;
    // }

    // .result-bottomleft::before{
    //     z-index: 1;
    //     width: calc(100% - 9 * 1px);
    //     height: calc(100% - 9 * 1px);
    //     content: "";
    //     display: block;
    //     background: linear-gradient(45deg, transparent 48px,  $white 0) bottom left;
    //     position: absolute;
    //     left: 4px;
    //     top: 4px;
    // }

    // .result-topleft{
    //     z-index: 2;
    //     background: linear-gradient(135deg,transparent calc(50px + 1 * 0.414px), rgb(119, 8, 10) 0) top left;
    // }

    
    // .result-topleft::before{
    //     z-index: 1;
    //     width: calc(100% - 9 * 1px);
    //     height: calc(100% - 9 * 1px);
    //     content: "";
    //     display: block;
    //     background: linear-gradient(135deg, transparent 50px,  $white 0) top left;
    //     position: absolute;
    //     left: 4px;
    //     top: 4px;
    // }

    // .result-topright{
    //     z-index: 2;
    //   background:linear-gradient(-135deg, transparent calc(50px + 1 * 0.414px), rgb(119, 8, 10) 0) top right;
    
    // }

    // .result-topright::before{
    //     z-index: 1;
    //     width: calc(100% - 9 * 1px);
    //     height: calc(100% - 9 * 1px);
    //     content: "";
    //     display: block;
    //     background: linear-gradient(-135deg, transparent 48px,  $white 0) top right;
    //     position: absolute;
    //     left: 4px;
    //     top: 4px;
    // }

    .myresult-text{   
        // text-transform: uppercase;
        color: gray;
        font-weight: bold;
    }

    .myresult-text.myresult-text-left{   
        display: flex; 
        justify-content: flex-end;
    }
}



