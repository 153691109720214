.kompo7rating-td {
    position: relative; 
    overflow: none;
    //word-break: break-word;
    word-wrap: break-word;
}

.kompo7rating {
    display:block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin:0%;
    padding:0%;
    cursor: pointer;
}

.kompo7rating.disabled{
    cursor: default;
}

.kompo7rating input:checked~.checkmark {
    background-image: url('../assets/images/Kreuz.svg') !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
    display: block;    
}

.kompo7rating input {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    opacity: 0;
}

.kompo7rating .checkmark {
    width: 100%;
    height: 100%;
    display: block;    
}

#kompo7ratingMessage {
    
}