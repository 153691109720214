.ReactPasswordStrength-input {
    display: block !important; 
    width: 100% !important;
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0 !important;
}

.ReactPasswordStrength-strength-desc {
    display: none;
}

.is-strength-4 .ReactPasswordStrength-strength-bar {
    border-right: 2px solid white;
}

.pwd-strength-input {
    width: 100%;
}

.pwd-strength-0 {
    color: #D1462F !important;
}

.pwd-strength-1 {
    color: #D1462F !important;
}

.pwd-strength-2 {
    color: #57B8FF !important;
}

.pwd-strength-3 {
    color: #57B8FF !important;
}

.pwd-strength-4 {
    color: #2FBF71 !important;
}

.pwd-strength-label {
    color: #6c757d;
    margin-top: 0.25rem;
}

.pwd-strength-text-label {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #6c757d;
}