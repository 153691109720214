
th{
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;  
    vertical-align: middle !important;
}

.th-button {
    color: $body-color;
    border: none;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold;
    //background-color:blue;

}

.th-button div, th div{
    display:flex !important;
    align-items: center !important; 
    margin:0px !important;
    align-content: center;
    align-self: center;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}


.th-button div span, th div span {
    margin: 0 !important;
    align-content: center;

}

.th-button:active,
.th-button:hover,
.th-button:visited,
.th-button:focus,
.th-button:link {
    background: transparent !important;
    color: $body-color;
    border: 0px !important;
    margin: 0;
    padding: 0;
}

.th-button:active {
    background-color: transparent !important;
    border: 0px !important;
}

.th-order:before,
.th-order:after {
    margin-left:0.2rem;
    border: 0.45rem solid transparent;
    content: "";
    display: block;
}

.th-order:before {
    border: 0.45rem solid transparent;
    content: "";
    display: block;
    border-bottom-color: $gray-300;
    margin-top: 0px;
}

.th-order:after {
    border: 0.45rem solid transparent;
    content: "";
    display: block;
    border-top-color: $gray-300;
    margin-top: 0.15rem;
}

.th-order.up:before {
    border-bottom-color: $primary;
}

.th-order.down:after {
    border-top-color: $primary;
}

.tablehead-border-bottom{
    border-bottom:0.25rem solid $primary !important;
}


