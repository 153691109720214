.motivation-next-button {
    width: 10rem;
    margin-top: 5%;
}

.motivation-pdfButton{
    justify-content: right;
    display: flex;
}

.motivations-container {
    display: flex; 
    margin: 0 auto;
    justify-content: space-evenly;
    min-height: 25rem;
    min-width: 35rem;
    flex-direction: column;
    width: 80%;
    position: relative;
    // border: 3px solid $primary;
    padding-top: 1rem;
}

.motivations-container .bottomRow{
    flex: 1;
    
}
.motivations-container .motivation-evaluation{
    display: flex;
    flex-direction: column;
    
    .motivation-evaluation-field{
        background-color: rgb(230, 230, 230);
        align-items: stretch;
        flex: 1;   
     }

     .motivation-evaluation-field.highlight{
        background-color:  $primary;
    }

    .motivation-evaluation-field.highlightLeft{
        background-color:   #5153b3; // #383980
    }
}

.motivations-nextButton-container{
    display: flex; 
    margin: 0 auto;
    flex-direction: column;
    width: 10%;
    padding-top: 2rem;

    .hintText{
        color:red;
        font-weight: bold;
    }

    .motivations-nextButton{
        width: 10rem;
    }
}

.motivations-result{ 
    width: 50%;
    position: relative;
    margin-left: 0rem;
    margin-top: -2rem;

    .cls-blue{fill:#383980}
    .cls-0{fill:rgb(252, 250, 250)}
    .cls-2,.cls-3{
        fill:none;
        stroke:#1d1d1b;
        stroke-miterlimit:10
    }
    .cls-2{stroke-width:2px}
    .cls-3{stroke-width:0.75px}
    .cls-4{font-size:11.79px;fill:#1d1d1b;font-family:ArialMT, Arial;}
    .cls-5{letter-spacing:-0.02em;}

    g:hover > text{
        display:block;
    }

    .motivation-list {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        user-select: none;
    }
}
