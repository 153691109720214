#imageheader {
    background-image: url('../assets/images/Header_b2500.png') !important;
    background-position: top;
    background-repeat: repeat-x;
    background-size:auto auto;
}

#imageheader #title{
    background-color:$gray-700;
    font-size:($font-size-base * 2.5);
    color:$white;
    opacity: 0.7;
    display:block;
    padding:0.25rem 2rem 0.25rem 2rem;
    margin-top:2rem;
}

#imageheader #subtitle{
    background-color:$primary;
    font-size: $font-size-lg;
    color:$white;
    opacity: 0.7;
    display:block;
    padding:0.25rem 2rem 0.25rem 2rem;
    margin-top:1rem;
    margin-bottom:2rem;
}