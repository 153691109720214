.confirm {
    z-index: ($zindex-modal + 1);
}
.confirm .modal-header{
    background-color:$primary;
    color:$white;
    padding: 0.25rem 1rem 0.25rem 1rem;
}
.confirm .modal-header .close{
    color:$white;
    text-shadow: 0 1px 0 $black;
}
.confirm .modal-footer{
    padding: 0.5rem 1rem;
}