.modal-dialog {
    //position: absolute;
    //top: 50%; //left: 50%;
    //transform: translateY(-50%) !important;
}

.modal-scroll-y {
    min-height: 10rem;
    height: 10rem;
    max-height: 40rem;
    overflow-y: auto;
}


/* Don't work in IE 11
.modal-header.edit{
    background-image: url('../assets/images/Bearbeiten.svg')  !important;
    background-repeat: no-repeat !important; 
    background-attachment: fixed;
    background-position: left center !important;       
    background-size: 3rem 3rem !important;
    padding-left:3rem !important;
}
*/