/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "_variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
// @import "../../node_modules/bootstrap/scss/input-group";
// @import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/utilities/api";
@import "../../node_modules/bootstrap/scss/helpers";
// @import "../../node_modules/bootstrap/scss/print";
@import "tablehead";
@import "tablebody";
@import "imageheader";
@import "confirm";
@import "loading";
@import "modal";
@import "multipleselect";
@import "competences";
@import "studentsbuttons";
@import "feedback";
@import "executionsgroupsmodal";
@import "menu";
@import "homepage";
@import "displayrole";
@import "displayschool";
@import "datepicker";
@import "reactpasswordstrength";
@import "kompo7rating";
@import "kompo7ratingresults";
@import "selfassessmentoverview";
@import "externalassessment";
@import "statistic";
@import "sponsor";
@import "help";
@import "interesttest";
@import "pravicypolicy";
@import "documents";
@import "verbexercise";
@import "motivations";
@import "myresults";

body {
    overflow-y: scroll !important;
}

.loginPage-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 1) 100%);
}

#login-sponsor img {
    object-fit: contain;
}

.login-pwd {
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.eye-icon {
    height: 2rem;
    padding-right: 1.7rem; 
    margin-right: 0.5rem;
    background-image: url('../assets/images/eye.svg');
    background-repeat: no-repeat ;
    position: absolute;

    &.closed{
        background-image: url('../assets/images/eye-closed.svg');
    }
}

.popover {
    max-width: 25vw;
}

.popover-body {
    overflow-y: auto;
    max-height: 40vh;
}

h1 {
    font-weight: bold;
    color: $secondary;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.text-gray {
    color: $gray-500;
}

.navbar-brand {
    font-weight: bold !important;
    font-size: $font-size-base * 1.1;
}

a {
    cursor: pointer;
}

.container-text-scroll-y {
    max-height: 15rem;
    overflow-y: auto;
}

.card-title {
    font-size: 1.2rem;
}

.card-title .link {
    font-size: 1.2rem;
    text-decoration: underline;
}

.alert {
    z-index: $zindex-alert;
}

.alert .fade {
    transition: opacity 0s linear;
}

.btn-link {
    border: 0px;
}

.btn-link:focus {
    outline: 1;
}

.text-lg {
    font-size: $font-size-lg;
}

.text-black {
    color: black !important;
}

.table-scroll {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.table-fixed {
    table-layout: fixed;
}

.container-scroll-y {
    overflow-y: auto !important;
}

button.edit {
    width: 2rem !important;
    height: 2rem;
    background-image: url('../assets/images/Bearbeiten_farbe.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    // background-size: 100%;
}

button.editinfo {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Info_dunkel.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

button.delete {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Loeschen_farbe.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.info {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('../assets/images/Info_dunkel.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: auto auto;
}

button.group {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Gruppe_bearbeiten_farbe.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

button.inquiry {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Email_farbe.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

button.resetpwd {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Zugangsdaten.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.resetpwdpdf {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Zugangsdaten_Drucker.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.foreignuserspdf {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Gruppe_drucken.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.print {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Drucken.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.release {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Freigabe.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.reset {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/Kreuz.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.add {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/add.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.up {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/keyboard_arrow_up-24px.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button.down {
    width: 2rem;
    height: 2rem;
    background-image: url('../assets/images/keyboard_arrow_down-24px.svg') !important;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100%;
}

button {
    outline: none !important;
}


.radiobutton-container {
    display: block;
    position: relative;
}

.table-border-left-action {
    border-left: 0.25rem solid $primary !important;
    padding-left: 0.5rem !important;
}

.tableTextarea{
    width: 100%;
}

.radiobutton {
    width: 100%;
    cursor: pointer;
    background-color: $primary;
    color: $white;
    margin: 0.0rem 0.15rem;
    padding: 0.3rem 0.5rem;
    outline: none !important;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
}

.radiobutton:hover {
    background-color: shade-color($primary, 10%);
}

.radiobutton:focus {
    box-shadow: $btn-focus-box-shadow;
}

.radiobutton-input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.radiobutton-input:checked+.radiobutton {
    background-color: $secondary;
    box-shadow: $btn-focus-box-shadow;
}

.radiobutton-invert {
    width: 100%;
    cursor: pointer;
    background-color: $white;
    color: $primary;
    margin: 0.0rem 0.15rem;
    padding: 0.3rem 0.5rem;
    outline: none !important;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    border: 0.1rem solid $primary;
}

.radiobutton-invert:hover {
    background-color: shade-color($white, 10%);
}

.radiobutton-invert:focus {
    box-shadow: $btn-focus-box-shadow;
}

.radiobutton-invert-input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.radiobutton-invert-input:checked+.radiobutton-invert {
    background-color: $white;
    color: $secondary;
    box-shadow: $btn-focus-box-shadow;
}

.popover-school {
    min-width: 25rem;
}

.react-datepicker__time-list {
    padding: 0px 0px 0px 0.5rem;
}

.react-datepicker__input-container input.disabled {
    background-color: rgb(233, 236, 239) !important;
}

/* Collapse */

.btn-collapse {
    background: none !important;
    color: black !important;
    border: none !important;
    outline: none !important;
    padding: 0;
    display: flex;
}

.btn-collapse:hover {
    background: none !important;
    color: black !important;
    border: none !important;
    outline: none !important;
}

.btn-collapse:focus {
    background: none !important;
    color: black !important;
    border: none !important;
    outline: none !important;
}

.btn-collapse:active {
    background: none !important;
    color: black !important;
    border: none !important;
    outline: none !important;
}

.btn-collapse:active:focus {
    background: none !important;
    color: black !important;
    border: none !important;
    outline: none !important;
}

.collapse-open {
    border: 0.35rem solid transparent;
    content: "";
    display: block;
    width: 0.35rem;
    border-top-color: $gray-700;
    margin: 0.7rem 0rem 0rem 0.25rem;
}

.collapse-close {
    border: 0.35rem solid transparent;
    content: "";
    display: block;
    width: 0.35rem;
    height: 0.25rem;
    border-left-color: $gray-700;
    margin: 0.45rem 0rem 0.0rem 0.25rem;
}

.text-ellipse {
    //word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
}

//Fix for jumping background when modal is open
body.modal-open {
    padding-right: 0px !important;
    overflow-y: auto;
}

@media (max-width: 575.98px) {
    .left-content-direction {
        flex-direction: row;
    }
}

@media (min-width: 576px) {
    .header-link {
        border-right: 1px solid #343a40 !important;
        padding-right: 1.0rem !important;
        padding-left: 1.0rem !important;
    }

    .header-link:last-child {
        border-right: 0px solid #343a40 !important;
        padding-right: 1.0rem !important;
        padding-left: 1.0rem !important;
    }

    .left-content-direction {
        flex-direction: column;
    }
}

.import-loader {
    background-color: transparent;
    /*position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.3);
    padding-top: 30%;*/
}

.th-smiley {
    width: 100%;
    height: 100%;
    max-width: 75px;
    max-height: 75px;
}

.th-refresh {
    width: 1.3rem;
    height: 1.3rem;
}

.th-smiley-container {
    text-align: center;
    vertical-align: top !important;
}

.th-assessment-container {
    min-width:48rem;
}

.th-assessment-column { 
    display: flex;
    flex-direction: column;

    .innerRow {
        align-items: stretch;
        flex: 1;
    }
}

// .th-school-example-container {
    // min-width: 23.66%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
// }

// .th-selfassessment-container {
    // min-width: 20%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
// }

.th-competence-container { 
    // display: flex;
    // min-width: 15%;
    justify-content: center;
    align-items: center;
    text-align: center;
    // word-wrap: break-word;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 5rem;
    overflow: hidden;
    z-index: $zindex-popover;
    display: block;
    border-bottom: 0.25rem solid $primary;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 110%;
    z-index: $zindex-fixed;
}

.content-left-padding {
    padding-left: 3rem + $input-btn-padding-y * 3 !important;
}

.content {
    position: relative;
    left: 0;
}

@media (min-width: 576px) {
    .content {
        position: absolute;
        top: 0rem;
        margin-top: 0rem !important;
        left: calc(100% / 12 * 4);
    }

    .header {
        position: fixed;
        top: 0;
        width: 100%;
        height: 5rem;
        overflow: hidden;
        z-index: $zindex-fixed;
        display: flex;
    }
}

@media (min-width: 768px) {
    .content {
        position: absolute;
        top: 0rem;
        margin-top: 0rem !important;
        left: calc(100% / 12 * 4);
    }
}

@media (min-width: 992px) {
    .content {
        position: absolute;
        top: 0rem;
        margin-top: 0rem !important;
        left: calc(100% / 12 * 3);
    }
}

@media (min-width: 1200px) {
    .content {
        position: absolute;
        top: 0rem !important;
        margin-top: 0rem !important;
        left: calc(100% / 12 * 2);
    }
}

.logo {
    background-image: url('../assets/images/KomPo7_logo.png') !important;
    background-repeat: no-repeat;
    background-size: 97%;
    background-position: 55% bottom;
    background-color: white;
}

@media (max-width: 575px) {
    .logo {
        background-size: 70%;
        background-position: 50% bottom;
    }
}


.content-margin {
    margin-top: 6.5rem !important;
    margin-bottom: 4.5rem !important;
    margin-left: 1rem !important;
}

.sticky {
    position: sticky;
    top: 4.5rem;
    z-index: 1020;
    display: block;
    max-width: 100%;
    width: 100%;
    padding-top: 2rem !important;
}

.sticky2 {
    position: sticky;
    top: 4.5rem;
    z-index: 1020;
    display: block;
    max-width: 100%;
    width: 100%;
    padding-top: 1rem !important;
}

.row .row-striped:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

/**
 * FOR IE
 */

img {
    min-width: 1px;
    min-height: 1px;
    -ms-interpolation-mode: bicubic !important;
}

.th-min-width {
    max-width: 1% !important;
    min-width: 1% !important;
    width: 1% !important;
}

.prop-row {
    border-top: 1px solid #dee2e6;
}

.prop-row.top {
    border-top: none;
}

.prop-row-title {
    font-weight: bold;
}

.offline {
    z-index: $zindex-offline;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    color: $white;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
}

.offline span {
    background-color: $primary;
    border: solid 0.1rem $secondary;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
}

.hidden{
    max-height: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    opacity: 0 !important;
}

.form-check-input[type="checkbox"],
.form-check-input[type="radio"]{
    margin-left: -1.25rem;
    margin-right: 0.5rem;
}