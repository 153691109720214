.homepage-card-link{
    color:$white;    
}
.homepage-card-link:hover{
    color:$secondary;    
}

.homepage-card-body{
    background-color:rgba($primary,0.75);
    overflow: hidden;
}

.homepage-arrow{
    transform: rotate(90deg) !important;
    padding-bottom:0.2rem;
    margin-top:-0.5rem;
    margin-bottom:-0.5rem;
    font-size:2rem;
}

.homepage-card-body .card-title{
    font-size:1.5vw;
    height:8.0vw;
}

.card-img {
    min-height:16vw;
}
