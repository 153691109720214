.bit-iframe-container {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: calc(100vh - 11rem);
}

.bit-iframe-container-result {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; //height: calc(100vh - 11rem);
}

#bit-iframe {
    overflow: hidden;
    border: 0;
    width: 100%;
    height: calc(100% - 3.25rem);
}

.bit-smiley-1 {
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/images/Smiley_01.svg);
}

.bit-smiley-2 {
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/images/Smiley_05.svg);
}

.bit-smiley-3 {
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/images/Smiley_04.svg);
}

.bit-smiley-label {
    position: relative;
    top: 1.2rem;
    left: 1rem;
}

#container-bit-smiley-1 {
    display: block;
    position: absolute !important;
    left: calc(16.5% - 2.5rem);
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/images/Smiley_04.svg);
}

#container-bit-smiley-2 {
    display: block;
    position: absolute !important;
    left: calc(49.5% - 3rem);
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/images/Smiley_05.svg);
}

#container-bit-smiley-3 {
    display: block;
    position: absolute !important;
    left: calc(82.5% - 2.5rem);
    width: 4rem;
    height: 4rem;
    background-image: url(../assets/images/Smiley_01.svg);
}

#container-bar {
    display: block;
    position: relative;
}

#container-smiley {
    display: block;
    position: relative;
    left: 11rem;
    height: 4rem;
    width: calc(100% - 11.1rem) !important;
}