.stats-row {
    border-top: 1px solid #dee2e6;
}

.stats-row.top {
    border-top: none;
}

.stats-row.bottom {
    border-bottom: 1px solid #dee2e6;
}

.stats-row-title {
    font-weight: bold;
}

#statisticExecutionsChart {
    min-height: 20rem;
}

.kompo7ratingresults-rating-1 {
    border-left: 2.5rem solid rgba($primary,0.75);
    justify-content: center;
    display: inline-flex;
    align-items: stretch;
    flex-direction: column; 
    line-height: 1rem;    
}

.kompo7ratingresults-rating-2 {
    border-left: 2.5rem solid rgba($primary,0.2);
    justify-content: center;
    display: inline-flex;
    align-items: stretch;
    flex-direction: column;   
    line-height: 1rem; 
}

.kompo7ratingresults-rating-3 {
    border-left: 2.5rem solid rgba(#E30613,0.75);
    justify-content: center;
    display: inline-flex;
    align-items: stretch;
    flex-direction: column;    
    line-height: 1rem;
}

.kompo7ratingresults-xaxes{
    display:flex;
    padding-left:3rem;
    padding-top:0;
    margin-top:-1rem;

}
.kompo7ratingresults-xaxes div{
    max-width: 25%;
    min-width: 25%;
    width:25%;
    padding: 0 0.35rem 0 0.35rem;
    text-align: center;
}

.kompo7ratingresults-bar{
    height: 20rem;
}
.kompo7ratingresults-doughnut{
    height: 20rem;
}

.kompo7ratingresults-remarks{
    overflow-y: auto;
    height: 20rem;
    width:100%;
}

.kompo7ratingresults-remarks div:last-child{
    border:0px !important;
}

.stats-row > div:first-child{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
