.competences-td {
    position: relative; 
    overflow: none;
    //word-break: break-word;
    word-wrap: break-word;
}

.competences {
    display:block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin:0%;
    padding:0%;
    cursor: pointer;
}

.competences.disabled{
    cursor: default;
}

.competences input:checked~.checkmark {
    background-image: url('../assets/images/Kreuz.svg') !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 3rem 3rem;
    display: block;    
}

.competences input {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    opacity: 0;
}

.competences .checkmark {
    width: 100%;
    height: 100%;
    display: block;    
}

.kompog-row {
    height: 19rem;
}

.competence-kompog-table-body-label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.competence-kompog-table-body-label-p {
    // transform:rotate(-90deg);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: right;
    max-height: 250px;
}

// .assessment-container {
//     vertical-align: top !important;
//     min-width: 36%;
// }

// .assessment-text-container {
//     min-width: 55.5% !important;
// }

// .assessment-checkbox-container {
//     min-width: 4%;
// }

.assessment-check-container {
    text-align: center;
    vertical-align: top !important;
    max-width: 4%;
}

.disable-textarea {
    pointer-events: none;
}

.kompog-text-area {
    height: 100%;
    width: 100%;
    border: none;
    background-color: transparent;
    resize: none;
}
.kompog-text-area:focus {
    outline: none;
}
