td {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    vertical-align: middle !important;
}

.td-div {
    display: flex;
    align-items: center !important;
    margin: 0 !important;
    padding:0 !important;
}

.td-div span {
    margin: 0 !important;
    align-content: center;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tb-icon {
    width: 100%;
    height: auto;
    margin: 0;
    //min-width: 1rem;
    max-width:2rem;
}

.icon-certified {
    width: 100%;
    height: auto;
    margin: 0;
    //min-width: 1rem;
    max-width:2rem;
}

#tablebodyaction {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
}

#tablebodyaction div {
    margin-right: 0.25rem !important;    
}

#tablebodyaction div:last-child {
    margin-right: 0rem !important;
}

tr.highlight{
    background-color: $danger !important;
}